<template>
	<div class="main">
		<!-- 我的作品 -->
		<!-- 1111 -->
		<div class="nav-lf">
			<MusicType :width="952" :type="type" :style="{ paddingLeft: num + 'px' }" :tabsList="tabsList"
				:dropdownList="dropdownList"></MusicType>
		</div>
		<div class="main-box">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import MusicType from "@/components/content/MusicType.vue";
import { getMyInfo, } from "@/utils/common/user";
export default {
	props: {},
	computed: {},
	components: {
		MusicType
	},
	mounted() {
		
		
	},
	data() {
		return {
			num:'',
      type:false,
      dropdownList:[],
			// tabs数据
			tabsList: [
				{
					name: this.$t("authorize_title"),
					url: '/personalCenter/authenticationinfo/authenticationinfo1'
				},
			],
			// tabsList: [{
			// 	name: '成品音乐',
			// 	url: '/personalCenter/myWork/workproducthmusic'
			// },
			// {
			// 	name: 'DEMO(海外)',
			// 	url: '/personalCenter/myWork/workseademo'
			// },
			// {
			// 	name: 'DEMO(内地)',
			// 	url: '/personalCenter/myWork/workinlanddemo'
			// },
			// {
			// 	name: '作品协作区',
			// 	url: '/personalCenter/myWork/workarea'
			// },
			// ],
		}
	},
	methods: {
		getMyInfo,
	}
}
</script>

<style lang="less" scoped>
.main {
	// color: palevioletred;
	::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @lineColor;
		}

	.nav-lf {
		margin-left: 28px;
	}
	.main-box {
		margin-left: 30px;
	}
}
</style>